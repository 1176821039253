import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/Layout"
import * as styles from '../styles/template.module.scss'
import { Link } from "gatsby"

import right from '../images/blog/chevron-down.png'

const Template = ({ data, pageContext }) => {
    const { next, prev, new1, new2, new3 } = pageContext;
    const { markdownRemark } = data;
    const title = markdownRemark.frontmatter.title;
    const date = markdownRemark.frontmatter.date;
    const author = markdownRemark.frontmatter.author;
    const html = markdownRemark.html;
    return (
        <Layout >
            <section className={styles.blogPost}>
                <h1>{title}</h1>
                <div className={styles.titMsg}><span>{date}</span><span>{author}</span> </div>
                <div className={styles.line}></div>
                <div className={styles.routeLink}>
                    Home / Blog / <span>{title}</span>
                </div>
                <div dangerouslySetInnerHTML={{ __html: html }} className={styles.content} />
                <div className={styles.line2}></div>
                <div className={styles.control}>
                    <div>
                        {prev && <Link to={prev.frontmatter.path}>
                            Prev <div className={styles.lineBtn}></div>
                        </Link>}
                    </div>
                    <div>
                        <Link to="/blog">
                            VIEW ALL<div className={styles.lineBtn}></div>
                        </Link>
                    </div>
                    <div>
                        {next && <Link to={next.frontmatter.path}>
                            Next<div className={styles.lineBtn}></div>
                        </Link>}
                    </div>
                </div>

                <div className={styles.recent}>
                    <h2>Recent Posts</h2>
                    <div className={styles.content}>
                        <Link to={new1.frontmatter.path} >

                            <img className={styles.iiimg} src={require("../static/markdown/" + new1.frontmatter.name + ".png").default} alt='' />
                            <h3>{new1.frontmatter.title}</h3>
                            <p className={styles.des}>{new1.frontmatter.description}</p>
                            <div className={styles.little}>
                                <span>{new1.frontmatter.date}</span> <p> View more <img src={right} alt="" /></p>
                            </div>
                        </Link>
                        <Link to={new2.frontmatter.path} >

                            <img className={styles.iiimg} src={require("../static/markdown/" + new2.frontmatter.name + ".png").default} alt='' />
                            <h3>{new2.frontmatter.title}</h3>
                            <p className={styles.des}>{new2.frontmatter.description}</p>
                            <div className={styles.little}>
                                <span>{new2.frontmatter.date}</span> <p> View more <img src={right} alt="" /></p>
                            </div>
                        </Link>
                        {/* <Link to={new3.frontmatter.path} >

                            <img className={styles.iiimg} src={require("../static/markdown/" + new3.frontmatter.name + ".png").default} alt='' />
                            <h3>{new3.frontmatter.title}</h3>
                            <p className={styles.des}>{new3.frontmatter.description}</p>
                            <div className={styles.little}>
                                <span>{new3.frontmatter.date}</span> <p> View more <img src={right} alt="" /></p>
                            </div>
                        </Link> */}
                        <Link></Link>
                    </div>
                </div>
            </section>
        </Layout >
    )
}
export const query = graphql`
  query($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      frontmatter {
        date 
        title
        path
        author
        name
      }
    }
  }
`
export default Template

