// extracted by mini-css-extract-plugin
export var blogPost = "template-module--blogPost--+T2zR";
export var titMsg = "template-module--titMsg--rMpis";
export var line = "template-module--line--H4aKM";
export var routeLink = "template-module--routeLink--wCtIM";
export var content = "template-module--content--n4yjG";
export var line2 = "template-module--line2--Qce-X";
export var control = "template-module--control--98Whl";
export var lineBtn = "template-module--lineBtn--mVLNM";
export var recent = "template-module--recent--Outw8";
export var iiimg = "template-module--iiimg--JdFG1";
export var des = "template-module--des--RdoHT";
export var little = "template-module--little--O0Am8";
export var gatsbyRespImageBackgroundImage = "template-module--gatsby-resp-image-background-image--BR2II";